import { Button, Form, Input, message } from 'antd';
import { useNavigate, } from "react-router-dom";
import { useState } from 'react';
import { req, check_414 } from "../network";
import "./login.css";

export default function Check() {
	const [userName, setUserName] = useState("");
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const check_phone = (phone) => {
		if (/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(phone)) {
			return true;
		}
		return false;
	};
	return (
		<div className="login">
			<div className='login-form'>
				<div>
					<h3 style={{ textAlign: 'center' }}>客户工单管理系统</h3>
				</div>
				<Form
					name="login"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					style={{
						maxWidth: 600,
					}}
					initialValues={{
						remember: true,
					}}
					onFinish={async () => {
						try {
							const res = await req.post(`/checkclientstate`, {
								account: userName
							});
							//console.log(res);
							if (res.data.data === 1) {
								navigate(`/reg/${userName}`);
							} else {
								navigate(`/login/${userName}`);
							}
						} catch (e) {
							check_414(e, messageApi, navigate);
						}
					}}
					onFinishFailed={() => { }}
					autoComplete="off"
				>
					<Form.Item
						label="手机号"
						name="username"
						rules={[
							{
								required: true,
								message: '请输入你的手机号!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || check_phone(value)) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('请输入有效手机号!'));
								},
							}),
						]}
					>
						<Input value={userName} onChange={(e) => setUserName(e.target.value)} />
					</Form.Item>

					<Form.Item
						wrapperCol={{
							offset: 8,
							span: 16,
						}}
					>
						<Button type="primary" htmlType="submit">
							下一步
						</Button>
					</Form.Item>
				</Form>
			</div>
			{contextHolder}
		</div>
	);
}