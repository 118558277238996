import { Button, Space, Table, message, Tag, Tooltip, Select, Modal } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { FileSearchOutlined, PayCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { req, check_414 } from "../network";
import moment from 'moment';

export default function Hosting() {
	const navigate = useNavigate();
	const page = useRef({
		current: 1,
		total: 0,
		pageSize: 15
	});
	const [modal, modalContextHolder] = Modal.useModal();
	const [messageApi, contextHolder] = message.useMessage();
	const [pageReload, setPageReload] = useState(1);
	void setPageReload;
	const [dataList, setDataList] = useState([]);
	const [dueSorter, setDueSorter] = useState(false);
	void setDueSorter;
	const { current, sorter, state = "active", type = "all" } = useParams();
	const [sortedInfo, setSortedInfo] = useState({});
	const [payLoading, setPayLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [filterList, setFilterList] = useState([]);
	const [filterTypeList, setFilterTypeList] = useState([]);
	const [currentFilter, setCurrentFilter] = useState(state);
	const [currentTypeFilter, setCurrentTypeFilter] = useState(type);
	const translate_status = (text) => {
		const map = {
			"active": '使用中',
			"change": "改配",
			"renew": "续费中",
			"take": "上架中",
			"down": "下架中",
			"end": "下架"
		};
		return map[text] === undefined ? text : map[text];
	};
	const translate_type = (text) => {
		const map = {
			all: "全部",
			bandwidth: "带宽",
			deposit: "托管",
			hdd: "硬盘",
			ip: "ip地址",
			proxyserver: "代理产品",
			ram: "内存",
			rent: "租用",
			safe: "防御",
			service: "增值服务"
		};
		return map[text] === undefined ? text : map[text];
	};
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: '产品信息',
			with: 200,
			render(text, record) {
				return <div style={{ maxWidth: 200 }}>
					<p>{record.info_for_client}</p>
				</div>
			}
		},
		{
			title: 'IP',
			with: 200,
			render(text, record) {
				return <div style={{ maxWidth: 200 }}>
					<p>{currentTypeFilter === "end" ? record.last_product_pack.assigned_ip : record.Product.CurrentPackage.assigned_ip}</p>
				</div>
			}
		},
		{
			title: '类型',
			dataIndex: 'Type',
			key: 'Type',
			render(text) {
				return <p>{translate_type(text)}</p>
			}
		},
		{
			title: "工单状态",
			render(text, record) {
				return <Tooltip title={translate_status(record.Status)}>
					<Tag icon={record.Status === "end" ? <CheckCircleOutlined /> : null} color={record.Status === "end" ? 'success' : 'processing'}>
						{translate_status(record.Status)}
					</Tag>
				</Tooltip>
			}
		},
		// {
		// 	title: '流程状态',
		// 	render(text, record) {
		// 		return <Space.Compact>
		// 			<Tooltip title={record.FinanceStatus === "completed" ? '完成' : '处理中'}>
		// 				<Tag icon={record.FinanceStatus === "completed" ? <CheckCircleOutlined /> : <SyncOutlined spin />} color={record.FinanceStatus === "completed" ? 'success' : 'processing'}>
		// 					财务
		// 				</Tag>
		// 			</Tooltip>
		// 			<Tooltip title={record.ExamStatus === "completed" ? '完成' : '处理中'}>
		// 				<Tag icon={record.ExamStatus === "completed" ? <CheckCircleOutlined /> : <SyncOutlined spin />} color={record.ExamStatus === "completed" ? 'success' : 'processing'}>
		// 					审核
		// 				</Tag>
		// 			</Tooltip>
		// 		</Space.Compact>
		// 	}
		// },
		{
			title: '付款周期',
			dataIndex: 'PayCycle',
			key: 'PayCycle',
		},
		{
			title: '付款金额',
			dataIndex: 'NextPayamount',
			key: 'NextPayamount',
			render(text){
				return <p className='money-tag'>{text}</p>
			}
		},
		{
			title: '到期日期',
			dataIndex: 'NextDuedate',
			key: 'NextDuedate',
			sorter: true,
			sortOrder: sortedInfo.columnKey === 'NextDuedate' ? sortedInfo.order : null,
			render(text) {
				return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
			}
		},
		{
			title: '开通日期',
			dataIndex: 'OpenDate',
			key: 'OpenDate',
			render(text) {
				return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
			}
		},
		// {
		// 	title: '终止日期',
		// 	dataIndex: 'TerminationDate',
		// 	key: 'TerminationDate',
		// 	render(text) {
		// 		return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
		// 	}
		// },
		// {
		// 	title: '创建日期',
		// 	dataIndex: 'created_at',
		// 	key: 'created_at',
		// 	render(text) {
		// 		return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
		// 	}
		// },
		// {
		// 	title: '更新日期',
		// 	dataIndex: 'updated_at',
		// 	key: 'updated_at',
		// 	render(text) {
		// 		return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
		// 	}
		// },
		{
			title: '操作',
			render(text, record) {
				return <div>
					<Space>
						<Button type='primary' icon={<PayCircleOutlined />} loading={payLoading} disabled={record.Status !== "active"} size='small' onClick={async () => {
							setPayLoading(true);
							try {
								const res = await req.post(`/client/hosting/renew?id=${record.id}`);
								//console.log(res.data.data);
								if (res.data.data.code === 2) {
									modal.confirm({
										title: '提示',
										icon: <ExclamationCircleOutlined />,
										content: '存在活跃订，是否继续支付？',
										okText: '确认',
										cancelText: '取消',
									}).then((confirm) => {
										if (confirm) {
											const pay_url = decodeURIComponent(res.data.data.payurl);
											window.location.href = pay_url;
										}
									});
								} else if (res.data.data.code === 1) {
									const pay_url = decodeURIComponent(res.data.data.payurl);
									window.location.href = pay_url;
								}
								//const pay_url = decodeURIComponent(res.data.data.payurl);
								//window.location.href = pay_url;
							} catch (e) { check_414(e, messageApi, navigate); }
							setPayLoading(false);
						}}>续费</Button>
						<Button icon={<FileSearchOutlined />} size='small' onClick={() => {
							navigate(`/home/history/${record.id}/${JSON.stringify({ page: page.current.current, sorter: dueSorter, state: currentFilter, type: currentTypeFilter })}`);
						}}>续费记录</Button>
					</Space>
				</div>
			}
		}
	];

	useEffect(() => {
		setSortedInfo({
			order: (sorter === "descend" || sorter === "ascend") ? sorter : "none",
			columnKey: 'NextDuedate',
		});
		const fetch = async () => {
			setTableLoading(true);
			try {
				const res = await req.get(`/client/hosting/list?page=${current}&perpage=${page.current.pageSize}&sort_by_expiry=${sorter}&status=${currentFilter}&type=${currentTypeFilter}`);
				//console.log(res);
				page.current.current = res.data.data.current_page;
				page.current.pageSize = res.data.data.per_page;
				page.current.total = res.data.data.total;
				setDataList(res.data.data.rows);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
			setTableLoading(false);
		};
		fetch();
	}, [pageReload, messageApi, navigate, dueSorter, current, sorter, currentFilter, currentTypeFilter]);


	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await req.get(`/client/hosting/states/list`);
				setFilterList(res.data.data);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		};
		fetch();
	}, [messageApi, navigate]);

	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await req.get(`/client/hosting/type/list`);
				setFilterTypeList(res.data.data);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		};
		fetch();
	}, [messageApi, navigate]);

	return (
		<div style={{ minWidth: 1300, padding: 8 }}>
			<div>
				<Space>
					<span>工单状态：</span>
					<Select style={{ width: 200 }} options={filterList.map((item) => {
						const [value] = Object.keys(item);
						return { value, label: item[value] };
					})} value={currentFilter} onChange={(e) => {
						//console.log(e);
						setCurrentFilter(e);
						navigate(`/home/hosting/${page.current.current}/${dueSorter}/${e}/${currentTypeFilter}`);
					}}></Select>
					<span>工单类型：</span>
					<Select style={{ width: 200 }} options={filterTypeList.map((item) => {
						const [value] = Object.keys(item);
						return { value, label: item[value] };
					})} value={currentTypeFilter} onChange={(e) => {
						//console.log(e);
						setCurrentTypeFilter(e);
						navigate(`/home/hosting/${page.current.current}/${dueSorter}/${currentFilter}/${e}`);
					}}></Select>
				</Space>
			</div>
			<div style={{ marginTop: 10 }}>
				<Table loading={tableLoading} size='small' dataSource={dataList} columns={columns} pagination={{
					current: page.current.current,
					pageSize: page.current.pageSize,
					total: page.current.total,
					onChange(current, pageSize) {
						page.current.current = current;
						page.current.pageSize = pageSize;
						//setPageReload(r => r + 1);
						navigate(`/home/hosting/${page.current.current}/${dueSorter}/${currentFilter}/${currentTypeFilter}`);
					}
				}}
					// expandable={{
					// 	expandedRowRender: (record) => (
					// 		<div>
					// 			<Space>
					// 				<div><p>工单状态:</p></div>
					// 				<div>
					// 					{
					// 						translate_status(record.Status)
					// 					}
					// 				</div>
					// 			</Space>
					// 		</div>
					// 	),
					// }}
					rowKey="id"
					onChange={(pagination, filters, sorter) => {
						//console.log(sorter);
						setSortedInfo(sorter);
						const order = sorter.order === undefined ? "none" : sorter.order;
						setDueSorter(order);
						navigate(`/home/hosting/${page.current.current}/${order}/${currentFilter}/${currentTypeFilter}`);
					}}
				/>
			</div>
			{contextHolder}
			{modalContextHolder}
		</div>
	);
}