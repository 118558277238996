import { Button, Form, Input, message, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { req, check_414 } from "../network";
import qs from "qs";
import localforage from "localforage";
import "./login.css";

export default function Register() {
	const [userName, setUserName] = useState("");
	const [userPass, setUserPass] = useState("");
	const [confirmUserPass, setConfirmUserPass] = useState("");
	const [smscode, setSmscode] = useState("");
	const { account } = useParams();
	const navigate = useNavigate();
	const [formHandler] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const [disableSms, setDisableSms] = useState(false);
	const [disableSmsText, setDisableSmsText] = useState("");
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setUserName(account);
		formHandler.setFieldValue("username", account)
	}, [account, formHandler]);

	const check_phone = (phone) => {
		if (/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(phone)) {
			return true;
		}
		return false;
	}
	return (
		<div className="login">
			<div className='login-form'>
				<div>
					<h3 style={{ textAlign: 'center' }}>客户工单管理系统</h3>
				</div>
				<Form
					form={formHandler}
					name="login"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					style={{
						maxWidth: 600,
					}}
					initialValues={{
						remember: true,
					}}
					onFinish={async () => {
						setLoading(true);
						try {
							const res = await req.post(`/clientactivation`, qs.stringify({
								account: userName,
								password: userPass,
								confirmpwd: confirmUserPass,
								smscode
							}));
							await localforage.setItem("token", res.data.data.token);
							navigate(`/home/hosting/1/none/default/all`);
						} catch (e) {
							if (e && e.response && e.response.status === 444) {
								messageApi.error(`异常错误状态:${e.response.data.message}`);
								setTimeout(() => {
									navigate(`/check`);
								}, 800);
								return;
							}
							check_414(e, messageApi, navigate);
						}
						setLoading(false);
					}}
					onFinishFailed={() => { }}
					autoComplete="off"
				>
					<Form.Item
						label="手机号"
						name="username"
						rules={[
							{
								required: true,
								message: '请输入你的手机号!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || check_phone(value)) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('请输入有效手机号!'));
								},
							}),
						]}
					>
						<Input value={userName} onChange={(e) => setUserName(e.target.value)} />
					</Form.Item>

					<Form.Item
						label="密码"
						name="password"
						rules={[
							{
								required: true,
								message: '请输入你的密码!',
							},
						]}
					>
						<Input.Password value={userPass} onChange={(e) => setUserPass(e.target.value)} />
					</Form.Item>

					<Form.Item
						dependencies={["password"]}
						label="确认密码"
						name="confirm_password"
						rules={[
							{
								required: true,
								message: '请输入你的确认密码!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('确认密码与密码不匹配!'));
								},
							}),
						]}
					>
						<Input.Password value={confirmUserPass} onChange={(e) => setConfirmUserPass(e.target.value)} />
					</Form.Item>

					<Form.Item
						label="验证码"
						name="smscode"
						rules={[
							{
								required: true,
								message: '请输入你的验证码!',
							},
						]}
					>
						<Space style={{ width: '100%' }}>
							<Input value={smscode} onChange={(e) => setSmscode(e.target.value)} />
							<Button onClick={async () => {
								if (userName === "" || !check_phone(userName)) {
									messageApi.error("请填写有效手机号码");
									return;
								}
								try {
									await req.post(`/sms/sendcode`, qs.stringify({
										phone: userName
									}));
									messageApi.success(`发送成功`);
									setDisableSms(true);
									const wait_time = 60;
									setDisableSmsText(`${wait_time}S`);
									const timeout = (count) => {
										if (count <= 0) {
											setDisableSms(false);
											return;
										}
										setDisableSmsText(`${count}S`);
										setTimeout(() => {
											timeout(--count);
										}, 1000);
									};
									timeout(wait_time);
								} catch (e) {
									if (e && e.response && e.response.status === 444) {
										messageApi.error(`异常错误状态:${e.response.data.message}`);
										setTimeout(() => {
											navigate(`/check`);
										}, 800);
										return;
									}
									check_414(e, messageApi, navigate);
								}
							}} type='primary' loading={disableSms}>{disableSms ? disableSmsText : '发送'}</Button>
						</Space>
					</Form.Item>

					<Form.Item
						wrapperCol={{
							offset: 8,
							span: 16,
						}}
					>
						<Button loading={loading} type="primary" htmlType="submit">
							激活
						</Button>
					</Form.Item>
				</Form>
			</div>
			{contextHolder}
		</div>
	);
}