import {  Table, message, Row, Col } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { req, check_414 } from "../network";
import moment from 'moment';

export default function Transaction() {
	const navigate = useNavigate();
	const page = useRef({
		current: 1,
		total: 0,
		pageSize: 15
	});

	const [messageApi, contextHolder] = message.useMessage();
	const [pageReload, setPageReload] = useState(1);
	const [dataList, setDataList] = useState([]);
	const { current, sorter } = useParams();
	const [tableLoading, setTableLoading] = useState(false);
	const translate_type = {
		"hosting_renew":"工单续费",
		"open":"活跃",
		"closed":"结束",
		"trade_success":"交易成功"
	};
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: '工单ID',
			dataIndex: 'HostingID',
			key: 'HostingID',
		},
		{
			title: '金额',
			dataIndex: 'Amount',
			key: 'Amount',
			render(text){
				return <p className='money-tag'>{text}</p>
			}
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
			render(text,record){
				return <p>{translate_type[text.toLowerCase()]===undefined?text:translate_type[text.toLowerCase()]}</p>
			}
		},
		{
			title: '状态',
			dataIndex: 'Status',
			key: 'Status',
			render(text,record){
				return <p>{translate_type[text.toLowerCase()]===undefined?text:translate_type[text.toLowerCase()]}</p>
			}
		},
		{
			title: '交易状态',
			dataIndex: 'TradeStatus',
			key: 'TradeStatus',
			render(text,record){
				return <p>{translate_type[text.toLowerCase()]===undefined?text:translate_type[text.toLowerCase()]}</p>
			}
		},
		{
			title: '到期日期',
			dataIndex: 'expire_time',
			key: 'expire_time',
			render(text) {
				return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
			}
		},
		{
			title: '创建日期',
			dataIndex: 'created_at',
			key: 'created_at',
			render(text) {
				return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
			}
		},
		{
			title: '更新日期',
			dataIndex: 'updated_at',
			key: 'updated_at',
			render(text) {
				return <p>{new moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
			}
		},
	];

	useEffect(() => {
		const fetch = async () => {
			setTableLoading(true);
			try {
				const res = await req.get(`/client/traninfo/list?page=${page.current.current === undefined ? 1 : page.current.current}&perpage=${page.current.pageSize === undefined ? 15 : page.current.pageSize}`);
				//console.log(res);
				page.current.current = res.data.data.data.current_page;
				page.current.pageSize = res.data.data.data.per_page;
				page.current.total = res.data.data.data.total;
				setDataList(res.data.data.data.rows);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
			setTableLoading(false);
		};
		fetch();
	}, [pageReload, messageApi, navigate, current, sorter]);

	return (
		<div style={{ minWidth: 1600,padding:8  }}>
			<div style={{ marginTop: 10 }}>
				<Table loading={tableLoading} size='small' dataSource={dataList} columns={columns} pagination={{
					current: page.current.current,
					pageSize: page.current.pageSize,
					total: page.current.total,
					onChange(current, pageSize) {
						page.current.current = current;
						page.current.pageSize = pageSize;
						setPageReload(r => r + 1);
					}
				}}
					expandable={{
						expandedRowRender: (record) => (
							<div style={{width:500}}>
								<Row>
									<Col span={5}>交易ID:</Col>
									<Col>{record.TradingID}</Col>
								</Row>
								<Row>
									<Col span={5}>支付ID:</Col>
									<Col>{record.PayID}</Col>
								</Row>
								<Row>
									<Col span={5}>详情:</Col>
									<Col>{record.detail}</Col>
								</Row>
							</div>
						),
					}}
					rowKey="id"
				/>
			</div>
			{contextHolder}
		</div>
	);
}