import { Layout, Menu, Avatar, Popconfirm, List, Dropdown, Button, message, Badge, Space, Drawer, Modal, Row, Col, Form, Input } from 'antd';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import "./home.css";
import { UserOutlined, NotificationOutlined, MailOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import localforage from 'localforage';
import { useEffect, useState, useRef } from 'react';
import { AlipayOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { check_414, req } from '../network';
import moment from 'moment';
const { Header, Content, Footer, Sider } = Layout;


export default function Home() {
	const navigate = useNavigate();
	const location = useLocation();
	const [selectKey, setSelectKey] = useState("/home");
	const [messageApi, contextHolder] = message.useMessage();
	const [userName, setUserName] = useState("");
	const [openDrawer, setOpenDrawer] = useState(false);
	const [profileOpen, setProfileOpen] = useState(false);
	const [profile, setProfile] = useState({});
	const [password, setPassword] = useState("");
	const [passconfirm, setPassconfirm] = useState("");
	const [loading, setLoading] = useState(false);
	const [formHandler] = Form.useForm();
	const [refreshLog, setRefreshLog] = useState(0);
	const [signInLog, setSignInLog] = useState([]);
	const [msgList, setMsgList] = useState([]);
	const [clearLoading, setClearLoading] = useState(false);
	const [refreshMsg, setRefreshMsg] = useState(0);
	const [msgListLoading, setmsgListLoading] = useState(false);
	const msgPageExceed = useRef(false);
	void setmsgListLoading;
	const msgPage = useRef({
		current: 1,
		total: 0,
		pageSize: 15
	});
	useEffect(() => {
		//console.log(location);
		if (location.pathname.includes("/home/hosting")) {
			setSelectKey("/home/hosting");
			return;
		}
		if (location.pathname.includes("/home/transaction")) {
			setSelectKey("/home/transaction");
			return;
		}
		if(location.pathname.includes("/home/profile")){
			setSelectKey("/home/profile");
			return;
		}
		setSelectKey("");
	}, [location]);


	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await req.get(`/client/info`);
				//console.log(res);
				setUserName(res.data.data.name);
				setProfile(res.data.data);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		};
		fetch();
	}, [messageApi, navigate]);

	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await req.get(`/client/loginlog/list`);
				setSignInLog(res.data.data);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		};
		fetch();
	}, [messageApi, navigate, refreshLog])

	// useEffect(() => {
	// 	const fetch = async () => {
	// 		try {
	// 			const res = await req.get(`/client/msg/list`);
	// 			console.log(res);
	// 			//setUserName(res.data.data.name);
	// 		} catch (e) {
	// 			check_414(e, messageApi, navigate);
	// 		}
	// 	};
	// 	fetch();
	// }, [messageApi, navigate])

	const [unReadCount, setUnReadCount] = useState(0);
	const msgVersion = useRef(0);
	useEffect(() => {
		msgVersion.current = msgVersion.current + 1;
		const fetch_unread_msg = async () => {
			try {
				const res = await req.get(`/client/message/unreadnum`);
				setUnReadCount(res.data.data);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		};
		const fetch_msg_list = async (current_version) => {
			const { current = 1, pageSize = 15 } = msgPage.current;
			try {
				const res = await req.get(`/client/message/list?page=${current}&perpage=${pageSize}`);
				//console.log(current_version,msgVersion.current);
				if (current_version !== msgVersion.current) {
					console.log("cancelled", current_version, msgVersion.current)
					return;
				}
				//console.log(res.data.data);

				msgPage.current.current = res.data.data.current_page;
				msgPage.current.pageSize = res.data.data.per_page;
				msgPage.current.total = res.data.data.total;
				if (res.data.data.rows.length <= msgPage.current.pageSize) {
					msgPageExceed.current = true;
				} else {
					msgPageExceed.current = false;
				}
				setMsgList(res.data.data.rows.map((item) => ({ msgid: item.msgid, content: item.content ? JSON.parse(item.content) : {} })));
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		}
		fetch_unread_msg();
		fetch_msg_list(msgVersion.current);
		const time_id = setInterval(() => {
			fetch_unread_msg();
			msgVersion.current = msgVersion.current + 1;
			fetch_msg_list(msgVersion.current);
		}, 20000);
		return () => {
			//console.log("clear time id");
			clearInterval(time_id);
		}
	}, [messageApi, navigate, refreshMsg]);


	const items = [
		{
			key: '/home/hosting',
			label: "续费工单",
			icon: <AlipayOutlined />
		},
		{
			key: '/home/transaction',
			label: "历史交易",
			icon: <MoneyCollectOutlined />
		},
		{
			key: '/home/profile',
			label: "用户中心",
			icon: <UserOutlined />
		},
		{
			key: '/home/notify',
			label: "消息通知",
			icon: <Badge size="small" count={unReadCount} offset={[80, 0]}>
				<NotificationOutlined />
			</Badge>
		},
	];
	return (
		<div className='home-page'>
			<Layout className='whole-layout'>
				<Sider>
					<div className="demo-logo-vertical" />
					<Menu theme="dark" mode="inline" selectedKeys={[selectKey]} items={items} onClick={(e) => {
						//console.log(e);
						if (e.key === "/home/notify") {
							setOpenDrawer(true);
							return;
						}
						if (e.key === "/home/hosting") {
							navigate(`${e.key}/1/none/default/all`)
							return;
						}
						navigate(`${e.key}`)
					}} />
				</Sider>
				<Layout className='main-layout'>
					<Header
						style={{
							padding: 0,
							background: `#FFF`,
						}}
					>
						<div className='avatar-part'>
							<Space>
								<div style={{ cursor: 'pointer' }}>
									<Dropdown placement="bottom" menu={{
										items: [{
											key: '1',
											label: <Button style={{ color: '#000', textAlign: 'center' }} type='link' onClick={() => { setRefreshLog(r => r + 1); setProfileOpen(true); }}>用户中心</Button>
										},
										{
											key: '2',
											label: <Popconfirm description="是否退出当前账号?" title="提示" onConfirm={async () => {
												await localforage.removeItem("token");
												navigate("/check");
											}}>
												<Button style={{ color: '#000', textAlign: 'center' }} type='link'>退出登录</Button>
											</Popconfirm>
										}]
									}}>
										<div>
											<Avatar size={32} icon={<UserOutlined />} />
											<span style={{ marginLeft: 10 }}>{userName}</span>
										</div>
									</Dropdown>
								</div>
								<div style={{ marginLeft: 30, cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
									<Badge size="small" count={unReadCount} onClick={() => {
										setOpenDrawer(true);
									}}>
										<NotificationOutlined
											style={{
												fontSize: 26,
											}}
										/>
									</Badge>
								</div>
							</Space>
						</div>
					</Header>
					<Content
						style={{
							margin: '12px 16px 12px',
						}}
						className='main-content'
					>
						<div className='main-stage'>
							<div
								className='inner-main-stage'
							>
								<div style={{ minHeight: 360 }}>
									<Outlet />
								</div>
							</div>
						</div>
					</Content>
					<Footer
						style={{
							textAlign: 'center',
							background: `#FFF`,
						}}
					>
						©{new Date().getFullYear()}
					</Footer>
				</Layout>
			</Layout>
			<Drawer title="消息通知" open={openDrawer} onClose={() => setOpenDrawer(false)}>
				<div>
					<div style={{ textAlign: 'right' }}>
						<Button size='small' loading={clearLoading} type='link' onClick={async () => {
							setClearLoading(true);
							try {
								await req.post(`/client/message/hasread`);
								messageApi.success("一键清除未读消息成功！");
								setRefreshMsg(r => r + 1);
							} catch (e) {
								check_414(e, messageApi, navigate);
							}
							setClearLoading(false);
						}}>全部已读</Button>
					</div>
					<div>
						<List loading={msgListLoading}

							// pagination={{
							// 	size: 'small',
							// 	showQuickJumper: false,
							// 	showSizeChanger: false,
							// 	current: msgPage.current.current,
							// 	pageSize: msgPage.current.pageSize,
							// 	total: 0,
							// 	itemRender(_, type, originalElement) {
							// 		if (type === 'prev') {
							// 			console.log(msgPage.current.current);
							// 			return <Button onClick={() => {
							// 				msgPage.current.current = msgPage.current.current - 1;
							// 				msgPage.current.pageSize = 15;
							// 				setRefreshMsg(r => r + 1);
							// 			}} disabled={msgPage.current.current === 1} style={{ color: msgPage.current.current === 1 ? 'gray' : 'black' }} size='small' type='link' icon={<LeftOutlined />}></Button>;
							// 		}
							// 		if (type === "next") {
							// 			console.log("next page render");
							// 			return <Button onClick={() => {
							// 				console.log("next page");
							// 				msgPage.current.current = msgPage.current.current + 1;
							// 				msgPage.current.pageSize = 15;
							// 				setRefreshMsg(r => r + 1);
							// 			}} style={{ color: msgPageExceed.current ? 'gray' : 'black' }} size='small' type='link' icon={<RightOutlined />}></Button>;
							// 		}
							// 		return <span>{msgPage.current.current}</span>
							// 	},
							// 	onChange(page, pageSize) {
							// 		console.log(page, pageSize);
							// 		// msgPage.current.current = page;
							// 		// msgPage.current.pageSize = pageSize;
							// 		// setRefreshMsg(r => r + 1);
							// 	}
							// }}
							dataSource={msgList} renderItem={(item, index) => {
								return <List.Item actions={[<Button size='small' type='link' onClick={async () => {
									try {
										const res = await req.get(`/client/message/info?message_id=${item.msgid}`);
										Modal.info({
											title: "短信通知",
											content: `${res.data.data.text}`
										});
										// msgList[index].content.ifread = 2;
										// setMsgList(msgList);
										setRefreshMsg(r => r + 1);
									} catch (e) {
										check_414(e, messageApi, navigate);
									}
								}}>查看</Button>]}>
									<List.Item.Meta avatar={<Badge dot={item.content.ifread === 1}><MailOutlined /></Badge>} title={item.content.text} />
								</List.Item>
							}}>
						</List>
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Space>
							<Button onClick={() => {
								msgPage.current.current = msgPage.current.current - 1;
								msgPage.current.pageSize = 15;
								setRefreshMsg(r => r + 1);
							}} disabled={msgPage.current.current === 1} style={{ color: msgPage.current.current === 1 ? 'gray' : 'black' }} size='small' type='link' icon={<LeftOutlined />}></Button>
							<span>{msgPage.current.current}</span>
							<Button onClick={() => {
								console.log("next page");
								msgPage.current.current = msgPage.current.current + 1;
								msgPage.current.pageSize = 15;
								setRefreshMsg(r => r + 1);
							}} disabled={msgPageExceed.current} style={{ color: msgPageExceed.current ? 'gray' : 'black' }} size='small' type='link' icon={<RightOutlined />}></Button>
						</Space>
					</div>
				</div>
			</Drawer>
			<Modal width={700} title="用户中心" open={profileOpen} footer={null} onCancel={() => setProfileOpen(false)}>
				<div>
					<h3>个人信息</h3>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>账号:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.account}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>昵称:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.name}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>姓名:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.real_name}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>地址:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.address}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>身份证:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.id_card}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>邮箱:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.mail}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>手机号:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.phone_number}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Col span={3} style={{ textAlign: 'right' }}>QQ:</Col>
						<Col style={{ paddingLeft: 15 }}>{profile.qq}</Col>
					</Row>
					<Row style={{ marginBottom: 5 }}>
						<Form form={formHandler} labelCol={{ span: 7 }} onFinish={async () => {
							setLoading(true);
							try {
								await req.post(`/client/edit`, {
									pass: password,
									passconfirm
								});
								messageApi.success("密码修改成功!");
								setPassword("");
								setPassconfirm("");
								formHandler.setFieldsValue({
									password: "",
									passconfirm: ""
								});
							} catch (e) { check_414(e, messageApi, navigate); }
							setLoading(false);
						}}>
							<Form.Item rules={[
								{
									required: true,
								},
							]} style={{ marginBottom: 5 }} name="password" label="密码">
								<Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
							</Form.Item>
							<Form.Item rules={[
								{
									required: true,
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error('确认密码与密码不匹配!'));
									},
								}),
							]} style={{ marginBottom: 5 }} name="passconfirm" label="确认密码">
								<Input.Password value={passconfirm} onChange={(e) => setPassconfirm(e.target.value)} />
							</Form.Item>
							<Form.Item wrapperCol={{ offset: 7 }}>
								<Button loading={loading} type="primary" htmlType="submit">
									修改密码
								</Button>
							</Form.Item>
						</Form>
					</Row>
				</div>
				<div>
					<h3>登录日志</h3>
					{
						signInLog.map((item, key) => {
							return <Row key={key}>
								<Col span={6}>{new moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}:</Col>
								<Col style={{ paddingLeft: 15 }}>{item.user_ip}</Col>
							</Row>
						})
					}
				</div>
			</Modal>
			{contextHolder}
		</div>
	);
}