import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message, Result, Button, Col, Row } from 'antd';
import { req } from "../network";
import moment from "moment";

export default function PageResult() {
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const { id } = useParams();
	const [resultData, setResultData] = useState({});
	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await req.get(`/client/traninfo?id=${id}`);
				//console.log(res);
				setResultData(res.data.data.data);
			} catch (e) { }
		};
		fetch();
	}, [navigate, messageApi, id]);
	return (
		<div>
			<Result status="success" title="支付成功" extra={[
				<Button type="primary" key="console" onClick={() => {
					navigate(`/home/hosting/1/none/default/all`);
				}}>回到主页</Button>
			]} subTitle={<div style={{ width: 450, margin: '0 auto', fontWeight: '900' }}>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>ID:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{resultData.id}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>工单ID:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{resultData.HostingID}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>状态:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{resultData.Status}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>交易状态:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{resultData.TradeStatus}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>交易ID:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{resultData.TradingID}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>类型:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{resultData.type}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>详情:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{resultData.detail}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>到期时间:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{new moment(resultData.expire_time).format("YYYY-MM-DD HH:mm:ss")}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>创建时间:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{new moment(resultData.created_at).format("YYYY-MM-DD HH:mm:ss")}</Col>
				</Row>
				<Row>
					<Col span={10} style={{ textAlign: 'right' }}>更新时间:</Col>
					<Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>{new moment(resultData.updated_at).format("YYYY-MM-DD HH:mm:ss")}</Col>
				</Row>
			</div>}>

			</Result>
			{contextHolder}
		</div>
	);
}