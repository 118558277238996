import {
	createBrowserRouter,
} from "react-router-dom";

import Login from "./pages/login";
import Launcher from "./pages/launcher";
import Home from "./pages/home";
import History from "./pages/order";
import Check from "./pages/check";
import Register from "./pages/register";
import Hosting from "./pages/hosting";
import Transaction from "./pages/transaction";
import Result from "./pages/result";
import Profile from "./pages/profile";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Launcher />,
	},
	{
		path: "/check",
		element: <Check />,
	},
	{
		path: "/reg/:account",
		element: <Register />,
	},
	{
		path: "/login/:account",
		element: <Login />,
	},
	{
		path: "/home",
		element: <Home />,
		children: [
			{
				path: "history/:hostingid/:ctx",
				element: <History />
			},
			{
				path: "hosting/:current/:sorter/:state/:type",
				element: <Hosting />
			},
			{
				path: "transaction",
				element: <Transaction />
			},
			{
				path: "result/:id",
				element: <Result />
			},
			{
				path: "profile",
				element: <Profile />
			}
		]
	},
]);