import localforage from "localforage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';

export default function Launcher() {
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	void contextHolder;
	useEffect(() => {
		const check = async () => {
			try {
				const token = await localforage.getItem("token");
				if (token === null || token === "") {
					navigate("/check");
					return;
				}
			} catch (e) {
				messageApi.error(`${JSON.stringify(e)}`);
				return;
			}
			navigate(`/home/hosting/1/none/default/all`);
		};
		check();
	}, [navigate, messageApi]);
	return (
		<div></div>
	);
}