import axios from "axios";
import localforage from "localforage";

export const req = axios.create({
	baseURL: `${window.apiUrl}`,
	timeout: 5000,
});

req.interceptors.request.use(async function (config) {
	// 在发送请求之前做些什么
	//console.log("config == ", config);
	try {
		const token = await localforage.getItem("token");
		config["headers"]["Authorization"] = `Bearer ${token}`;
	} catch (e) {

	}
	return config;
}, function (error) {
	// 对请求错误做些什么
	throw error;
});

req.interceptors.response.use(function (response) {
	// 对响应数据做点什么
	return response;
}, function (error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});


export const check_414 = (e,message,navigator)=>{
	//console.log(e.response.data.message);
	if(e && e.response){
		if(e.response.status ===404){
			message.error("404 not found");
			return;
		}
		message.error(`${e.response.data.message}`);
		if(e.response.status === 401){
			setTimeout(()=>{
				navigator(`/check`);
			},1500);
		}
	}else{
		//console.log(e);
		message.error(`${e}`);
	}
}

