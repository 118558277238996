import { Button, message, Row, Col, Form, Input, Table } from 'antd';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment"
import { req, check_414 } from "../network";
export default function Profile() {
	const [profile, setProfile] = useState({});
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const [formHandler] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [passconfirm, setPassconfirm] = useState("");
	const [signInLog, setSignInLog] = useState([]);
	const [refreshLog, setRefreshLog] = useState(0);
	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await req.get(`/client/info`);
				//console.log(res);
				setProfile(res.data.data);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		};
		fetch();
	}, [messageApi, navigate]);
	useEffect(() => {
		const fetch = async () => {
			try {
				const res = await req.get(`/client/loginlog/list`);
				setSignInLog(res.data.data);
			} catch (e) {
				check_414(e, messageApi, navigate);
			}
		};
		fetch();
	}, [messageApi, navigate, refreshLog]);

	const columns = [
		{
			title:"IP",
			dataIndex:"user_ip",
			key:"user_ip"
		},
		{
			title:"时间",
			render(text,record){
				return <span>{new moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</span>
			}
		},
	];

	return (
		<div style={{ padding: 10 }}>
			<div>
				<h3>个人信息</h3>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>账号:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.account}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>昵称:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.name}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>姓名:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.real_name}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>地址:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.address}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>身份证:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.id_card}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>邮箱:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.mail}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>手机号:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.phone_number}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Col span={4} style={{ textAlign: 'right',paddingRight:7  }}>QQ:</Col>
					<Col style={{ paddingLeft: 15 }}>{profile.qq}</Col>
				</Row>
				<Row style={{ marginBottom: 5,width:450 }}>
					<Form style={{width:'100%'}} form={formHandler} labelCol={{ span: 4,flex:'flex' }} onFinish={async () => {
						setLoading(true);
						try {
							await req.post(`/client/edit`, {
								pass: password,
								passconfirm
							});
							messageApi.success("密码修改成功!");
							setPassword("");
							setPassconfirm("");
							formHandler.setFieldsValue({
								password: "",
								passconfirm: ""
							});
							setRefreshLog(r => r + 1);
						} catch (e) { check_414(e, messageApi, navigate); }
						setLoading(false);
					}}>
						<Form.Item rules={[
							{
								required: true,
							},
						]} style={{ marginBottom: 5 }} name="password" label="密码">
							<Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
						</Form.Item>
						<Form.Item rules={[
							{
								required: true,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('确认密码与密码不匹配!'));
								},
							}),
						]} style={{ marginBottom: 5 }} name="passconfirm" label="确认密码">
							<Input.Password value={passconfirm} onChange={(e) => setPassconfirm(e.target.value)} />
						</Form.Item>
						<Form.Item wrapperCol={{ offset: 7 }}>
							<Button loading={loading} type="primary" htmlType="submit">
								修改密码
							</Button>
						</Form.Item>
					</Form>
				</Row>
			</div>
			<div>
				<h3>登录日志</h3>
				<Table size='small' rowKey="id" pagination={false} columns={columns} dataSource={signInLog} />
				{/* {
					signInLog.map((item, key) => {
						return <Row key={key}>
							<Col span={6}>{new moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}:</Col>
							<Col style={{ paddingLeft: 15 }}>{item.user_ip}</Col>
						</Row>
					})
				} */}
			</div>
			{contextHolder}
		</div>
	);
}